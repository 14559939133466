import React from 'react';
import './App.css';
import Main from './components/Main';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('UA-173433836-1');
  ReactGA.pageview('/home');

  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
