import React from 'react';

class PortfolioItem extends React.Component {
    render(){
        const details = this.props.details;
        return(
            <div className = "portfolio-item">
            <h3>{details.title}</h3>
            
            <div className="portfolio-content">
               <div className="portfolio-copy">
                        {
                           Object
                           .keys(details.copy)
                           .map(key=><div key={key}>{details.copy[key]} </div>)
                       }
                   
                   <br />
                   <h4>{details.listTitle}</h4>
                   <ul>
                       {
                           Object
                           .keys(details.listItems)
                           .map(key=><li key={key}>{details.listItems[key]} </li>)
                       }
                    </ul>
               </div>
               <div className="portfolio-image">
               {
                           Object
                           .keys(details.images)
                           .map(key=><img key={key} src={`./images/${details.images[key]}`} alt={details.images[key]} />)
                       }
                   
               </div>
                    </div>
       </div>
        );
    
}

};

export default PortfolioItem;