import React from 'react';

class Modal extends React.Component{
    render(){
        if(!this.props.showModal) {
            return null;
        }
    
        return(
            <div className="backdrop" onClick={this.props.onClose}>
                <div className="modal" onClick={event => event.stopPropagation()}>
                    <div className="modal-header">
                        <h2>{this.props.modalTitle}</h2>                 
                        <button onClick={this.props.onClose} className="close" />
                    </div>
                        {this.props.children}
                </div>
        </div>
        );
    }
}

export default Modal;