import React from 'react';
import PortfolioItem from './PortfolioItem';

class Portfolio extends React.Component{

    
    render(){
        const portfolioData = this.props.portfolioItems;
        return(
            <div className="portfolio-container"> 
                <div className="portfolio">
                    
                    <h2>PROJECT HIGHLIGHTS</h2>
                    { 
                        Object
                        .keys(portfolioData)
                        .map(key =><PortfolioItem key={key} details={portfolioData[key]}/>    ) 
                    }
                    <div className="last-word">Code samples and CV are available on request.</div>
                </div>
            </div>
        );
    }
}

export default Portfolio;