import React from 'react';

function LoadingAnimation(){

    return (
        <div className="la-double-bounce">
            <div />
            <div /> 
        </div>
    );
}

export default LoadingAnimation;
