import React from 'react';
import ParticlesBg from 'particles-bg'

function ParticleBackground(){
    let config = {
        num: [1, 2], //[4, 7]
        rps: 0.1,
        radius: [1, 5],//5, 40
        life: [1, 2],
        v: [2, 3],
        tha: [-40, 40],
        // body: "./img/icon.png", // Whether to render pictures
        // rotate: [0, 20],
        alpha: [0.1, 0],
        scale: [1, 0.1],
        position: "all", // all or center or {x:1,y:1,width:100,height:100}
        color: ["#96a29b", "#ff0000"],
        cross: "bround", // cross or bround
        random: 1,  // or null,
        g: 10,    // gravity
        // f: [2, -1], // force
        onParticleUpdate: (ctx, particle) => {
            ctx.beginPath();
            ctx.arc(particle.p.x, particle.p.y, particle.radius, 0, 2 * Math.PI);
            ctx.fillStyle = particle.color;
            ctx.fill();
            ctx.closePath();
        }
      };
    return <ParticlesBg type="custom" config={config} bg={true} />;
}

export default ParticleBackground;